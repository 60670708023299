<template>
	<v-sheet flat>
		<template v-if="!haveRole">
			<mw-edit :id="id" storeModule="users" :model="model" title-field="name">
				<template v-slot:email="slotProps">
					<v-text-field
						v-model="slotProps.data.email"
						:label="$t('misc.email')"
						@input="(v) => (slotProps.data['email'] = parseEmail(v))"
						:rules="[(v) => mwutils.validateEmail(v) || 'Invalid Email']"
					></v-text-field>
				</template>
			</mw-edit>
			<secondary-navigation>
				<template v-slot:main>
					<div v-if="id" class="ma-2">
						<nav-item
							:link="`/communication-history/${id}`"
							text="Communication History"
							icon="mdi-email"
						></nav-item>
						<v-divider class="mb-4 mt-3"></v-divider>
					</div>
				</template>
			</secondary-navigation>
		</template>
		<template v-else>
			<v-component :is="component" :id="id"></v-component>
		</template>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import User from "@/models/User";
import MwEdit from "@c/ui/MwEdit";
import EditClient from "@c/edits/EditClient";
import EditParticipant from "@c/edits/EditParticipant";
import SecondaryNavigation from "@c/navigation/Secondary";
import NavItem from "@/components/navigation/Item";

export default {
	name: "EditUser",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	data: () => {
		return {
			component: null, 
			haveRole: false
		}
	},
	computed: {
		model() {
			return User;
		},
		user() {
			return this.$store.state.users.data[this.id];
		}
	},
	methods: {
		parseEmail(a) {
			return a.toLowerCase().trim();
		},
	},
	components: {
		MwEdit,
		SecondaryNavigation,
		NavItem,
		EditClient, 
		EditParticipant
	},
	watch: {
		user(v){
			if( v ){
				switch ( v.role ){ 
					case "client":
					case "contract_manager":
						this.haveRole = true;
						this.component = "EditClient"
					break;
					case "participant":
						this.haveRole = true;
						this.component = "EditParticipant"
					break;
					default: 
						this.haveRole = false;
					break;
				}
			}
		}
	},
};
</script>

